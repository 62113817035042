.article{
    max-width: 800px;
    margin: 20px auto 6em;
    padding: 0 20px;
    word-break: break-all;
    font-family: tbnpmincho-std, serif;
    text-align: justify;
    h1,h2,h3,h4,h5,h6{
        font-family: dnp-shuei-gothic-gin-std, sans-serif;
        font-weight: 500;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 0;
        font-feature-settings: "palt";
        margin: 3em auto 1em;
        &:first-child{margin-top: 0;}
    }
    h1,h2{
        font-size: 1.4em;
        border-left: 5px solid rgba(255,255,255,.5);
        padding-left: 5px;
    }
    h3{
        font-size: 1.3em;
    }
    h3,h4,h5{
        font-size: 1.1em;
        letter-spacing: .25em;
        line-height: 2;
    }
    h4{
        border-left: 3px solid rgba(255,255,255,.5);
        padding-left: 7px;
    }
    h6{margin: 1em auto;}
    p,ul,ol,table{
        margin: 1em auto;
        &:first-child{margin-top: 0;}
    }
    ul,ol{
        margin: 2em auto;
        padding-left: 2em;
    }
    ul{
        list-style: circle;
    }
    ol{
        list-style: decimal;
    }
    li{
        list-style: inherit;
        line-height: 1.6;
        margin: 1em auto;
    }
    img{
        display: block;
        width: calc(100% + 40px);
        max-width: none;
        margin: 0 -20px;
    }
    a:not([class]){
        text-decoration: underline;
        margin:auto .25em;
    }
    em,strong{
        font-family: dnp-shuei-gothic-gin-std, sans-serif;
        font-weight: 500;
        font-style: normal;
        margin:auto .25em;
    }
    blockquote{
        display: block;
        padding:.5em 1em .5em 2em;
        margin: 1em auto;
        font-style: italic;
        background-color: rgba(255,255,255,.1);
        border-radius: 4px;
        opacity: .8;
    }
    table{
        width: 100%;
        margin: 2em auto;
    }
    th,td{
        line-height: 1.6;
        padding: 10px;
        vertical-align: middle;
    }
    th{
        font-family: dnp-shuei-gothic-gin-std, sans-serif;
        font-weight: 500;
        font-style: normal;
        background-color: rgba(255,255,255,.1);
        font-size: 1.1em;
        letter-spacing: 0;
        font-feature-settings: "palt";
    }
    td{
        background-color: rgba(255,255,255,.15);
    }
    hr{
        display: block;
        border: 0;
        height: 1px;
        background-color: rgba(255,255,255,.5);
        margin: 6em auto;
    }
    input,textarea{
        font-family: sans-serif;
        border: 0;
        border-radius: 4px;
        width: 100%;
        padding:5px 10px;
        color: #141915;
        background-color: rgba(255,255,255,.9);
        transition: background-color 400ms;
        &:focus{background-color: rgba(255,255,255,1);}
    }
    .btn{
        text-align: center;
        margin:2em auto 4em;
        a,button{
            font-family: dnp-shuei-gothic-gin-std, sans-serif;
            font-weight: 500;
            font-style: normal;
            display: block;
            width: 100%;
            max-width: 600px;
            margin: auto;
            line-height: 1;
            text-decoration: none;
            border: 1px solid rgba(255,255,255,.7);
            border-radius: 3px;
            padding: 1em 0;
        }
    }
    .youtube{
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        margin: 1em auto;
        &:first-child{margin-top: 0;}
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
