.single{
    &__fv{
        display: block;
        position: relative;
        z-index: 0;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        &__img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__info{
            position: absolute;
            left: 0;bottom: 10%;
            width: 100%;
            box-sizing: border-box;
            padding-right: 20vw;
            &--category{
                font-size: 16px;
                padding-left: 10px;
                text-shadow: 0 0 10px rgba(0,0,0,.7);
            }
            &--title{
                font-size: 1.5em;
                font-family: dnp-shuei-gothic-gin-std, sans-serif;
                font-weight: 500;
                font-style: normal;
                color: #141915;
                font-feature-settings: "palt";
                letter-spacing: 0;
                line-height: 1.8;
                padding: 0 .5em;
                span{
                    background-color: #fff;
                    padding: .3em 0;
                    white-space:pre-wrap;
                    box-shadow: .5em 0 0 #fff, -.5em 0 0 #fff;
                }
            }
            &--date{
                font-size: 10px;
                padding-left: 10px;
                text-shadow: 0 0 10px rgba(0,0,0,.7);
            }
        }
    }
    &__article{}
}
