.fixed-firstview{
    position: fixed;
    top: 0;left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    .swiper-container{height: 100%;}
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__contents{
        height: calc(100% - 30px);
        position: relative;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 30%,rgba(0,0,0,0.6) 100%);
    }
}
